@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,800);
@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
    /*background: #fff url('./ajax-loader.gif') center center no-repeat;*/
}

/*!* Icons *!*/
/*@font-face*/
/*{*/
/*    font-family: 'slick';*/
/*    font-weight: normal;*/
/*    font-style: normal;*/

/*    !*src: url('./fonts/slick.eot');*!*/
/*    !*src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');*!*/
/*}*/
/*!* Arrows *!*/
/*.slick-prev,*/
/*.slick-next*/
/*{*/
/*    font-size: 0;*/
/*    line-height: 0;*/

/*    position: absolute;*/
/*    top: 50%;*/

/*    display: block;*/

/*    width: 20px;*/
/*    height: 20px;*/
/*    padding: 0;*/
/*    -webkit-transform: translate(0, -50%);*/
/*    -ms-transform: translate(0, -50%);*/
/*    transform: translate(0, -50%);*/

/*    cursor: pointer;*/

/*    color: transparent;*/
/*    border: none;*/
/*    outline: none;*/
/*    background: transparent;*/
/*}*/
/*.slick-prev:hover,*/
/*.slick-prev:focus,*/
/*.slick-next:hover,*/
/*.slick-next:focus*/
/*{*/
/*    color: transparent;*/
/*    outline: none;*/
/*    background: transparent;*/
/*}*/
/*.slick-prev:hover:before,*/
/*.slick-prev:focus:before,*/
/*.slick-next:hover:before,*/
/*.slick-next:focus:before*/
/*{*/
/*    opacity: 1;*/
/*}*/
/*.slick-prev.slick-disabled:before,*/
/*.slick-next.slick-disabled:before*/
/*{*/
/*    opacity: .25;*/
/*}*/

/*.slick-prev:before,*/
/*.slick-next:before*/
/*{*/
/*    font-family: 'slick';*/
/*    font-size: 20px;*/
/*    line-height: 1;*/

/*    opacity: .75;*/
/*    color: white;*/

/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*.slick-prev*/
/*{*/
/*    left: -25px;*/
/*}*/
/*[dir='rtl'] .slick-prev*/
/*{*/
/*    right: -25px;*/
/*    left: auto;*/
/*}*/
/*.slick-prev:before*/
/*{*/
/*    content: '←';*/
/*}*/
/*[dir='rtl'] .slick-prev:before*/
/*{*/
/*    content: '→';*/
/*}*/

/*.slick-next*/
/*{*/
/*    right: -25px;*/
/*}*/
/*[dir='rtl'] .slick-next*/
/*{*/
/*    right: auto;*/
/*    left: -25px;*/
/*}*/
/*.slick-next:before*/
/*{*/
/*    content: '→';*/
/*}*/
/*[dir='rtl'] .slick-next:before*/
/*{*/
/*    content: '←';*/
/*}*/

/*!* Dots *!*/
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-size: 30px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: 0.25;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: white;
}

/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-Italic.806fd9eb.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-Bold.ff366e77.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-HeavyItalic.d21c3e2b.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Demi';
    src: url(/static/media/AvenirNext-DemiBoldItalic.c13c2a36.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Ultra';
    src: url(/static/media/AvenirNext-UltraLightItalic.3bb556b6.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-Heavy.f3340fbd.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-DemiBold.426f464e.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-Regular.8b3707a3.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-BoldItalic.8ac88e91.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-UltraLight.2688aeda.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-MediumItalic.4e9d0bbe.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next';
    src: url(/static/media/AvenirNext-Medium.45f91528.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}



.error-container {
    text-align: center;
    font-size: 180px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 800;
    margin: 20px 15px;
}
.error-container > span {
    display: inline-block;
    line-height: 0.7;
    position: relative;
    color: #ffb485;
}
.error-container > span {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}
.error-container > span:nth-of-type(1) {
    color: #d1f2a5;
    -webkit-animation: colordancing 4s infinite;
            animation: colordancing 4s infinite;
}
.error-container > span:nth-of-type(3) {
    color: #f56991;
    -webkit-animation: colordancing2 4s infinite;
            animation: colordancing2 4s infinite;
}
.error-container > span:nth-of-type(2) {
    width: 120px;
    height: 120px;
    border-radius: 999px;
}
.error-container > span:nth-of-type(2):before,
.error-container > span:nth-of-type(2):after {
    border-radius: 0%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 999px;
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4), inset 0 30px 0 rgba(239, 250, 180, 0.4),
        inset -30px 0 0 rgba(255, 196, 140, 0.4), inset 0 -30px 0 rgba(245, 105, 145, 0.4);
    -webkit-animation: shadowsdancing 4s infinite;
            animation: shadowsdancing 4s infinite;
}
.error-container > span:nth-of-type(2):before {
    transform: rotate(45deg);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}
@-webkit-keyframes shadowsdancing {
    0% {
        box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4), inset 0 30px 0 rgba(239, 250, 180, 0.4),
            inset -30px 0 0 rgba(255, 196, 140, 0.4), inset 0 -30px 0 rgba(245, 105, 145, 0.4);
    }
    25% {
        box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4), inset 0 30px 0 rgba(209, 242, 165, 0.4),
            inset -30px 0 0 rgba(239, 250, 180, 0.4), inset 0 -30px 0 rgba(255, 196, 140, 0.4);
    }
    50% {
        box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4), inset 0 30px 0 rgba(245, 105, 145, 0.4),
            inset -30px 0 0 rgba(209, 242, 165, 0.4), inset 0 -30px 0 rgba(239, 250, 180, 0.4);
    }
    75% {
        box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4), inset 0 30px 0 rgba(255, 196, 140, 0.4),
            inset -30px 0 0 rgba(245, 105, 145, 0.4), inset 0 -30px 0 rgba(209, 242, 165, 0.4);
    }
    100% {
        box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4), inset 0 30px 0 rgba(239, 250, 180, 0.4),
            inset -30px 0 0 rgba(255, 196, 140, 0.4), inset 0 -30px 0 rgba(245, 105, 145, 0.4);
    }
}
@keyframes shadowsdancing {
    0% {
        box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4), inset 0 30px 0 rgba(239, 250, 180, 0.4),
            inset -30px 0 0 rgba(255, 196, 140, 0.4), inset 0 -30px 0 rgba(245, 105, 145, 0.4);
    }
    25% {
        box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4), inset 0 30px 0 rgba(209, 242, 165, 0.4),
            inset -30px 0 0 rgba(239, 250, 180, 0.4), inset 0 -30px 0 rgba(255, 196, 140, 0.4);
    }
    50% {
        box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4), inset 0 30px 0 rgba(245, 105, 145, 0.4),
            inset -30px 0 0 rgba(209, 242, 165, 0.4), inset 0 -30px 0 rgba(239, 250, 180, 0.4);
    }
    75% {
        box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4), inset 0 30px 0 rgba(255, 196, 140, 0.4),
            inset -30px 0 0 rgba(245, 105, 145, 0.4), inset 0 -30px 0 rgba(209, 242, 165, 0.4);
    }
    100% {
        box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4), inset 0 30px 0 rgba(239, 250, 180, 0.4),
            inset -30px 0 0 rgba(255, 196, 140, 0.4), inset 0 -30px 0 rgba(245, 105, 145, 0.4);
    }
}
@-webkit-keyframes colordancing {
    0% {
        color: #d1f2a5;
    }
    25% {
        color: #f56991;
    }
    50% {
        color: #ffc48c;
    }
    75% {
        color: #effab4;
    }
    100% {
        color: #d1f2a5;
    }
}
@keyframes colordancing {
    0% {
        color: #d1f2a5;
    }
    25% {
        color: #f56991;
    }
    50% {
        color: #ffc48c;
    }
    75% {
        color: #effab4;
    }
    100% {
        color: #d1f2a5;
    }
}
@-webkit-keyframes colordancing2 {
    0% {
        color: #ffc48c;
    }
    25% {
        color: #effab4;
    }
    50% {
        color: #d1f2a5;
    }
    75% {
        color: #f56991;
    }
    100% {
        color: #ffc48c;
    }
}
@keyframes colordancing2 {
    0% {
        color: #ffc48c;
    }
    25% {
        color: #effab4;
    }
    50% {
        color: #d1f2a5;
    }
    75% {
        color: #f56991;
    }
    100% {
        color: #ffc48c;
    }
}

/* demo stuff */
* {
    box-sizing: border-box;
}
body {
    background-color: #328995;
    margin-bottom: 50px;
}
html,
button,
input,
select,
textarea {
    font-family: 'Montserrat', Helvetica, sans-serif;
    color: white;
}
h1 {
    text-align: center;
    margin: 30px 15px;
}
.zoom-area {
    max-width: 490px;
    margin: 30px auto 30px;
    font-size: 19px;
    text-align: center;
}
.link-container {
    text-align: center;
}
a.more-link {
    text-transform: uppercase;
    font-size: 13px;
    background-color: white;
    padding: 10px 15px;
    border-radius: 0;
    color: black;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 50px;
    letter-spacing: 1px;
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
    -webkit-animation: fade-in 1s ease;
            animation: fade-in 1s ease;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

@-webkit-keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

